import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './styles/reset.scss';
import './styles/global.scss';
import Navbar from './components/Navbar';
import ContactPage from './pages/contact/Contact';
import AboutPage from './pages/about/AboutPage';
import AuditsPage from './pages/audits/AuditsPage';
import ScoreCalculatorPage from './pages/score-calculator/ScoreCalculatorPage';
import Footer from './components/Footer';
import AnalyticsPage from './pages/analytics/Analytics';

function App() {
  return (
    <Router>
      <div className="page-container">
        <Navbar />
        <div className="page-content">
          <Routes>
            <Route path="/" element={<Navigate to="/audits" />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/audits" element={<AuditsPage />} />
            <Route path="/score-calculator" element={<ScoreCalculatorPage />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
