import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './Navbar.module.scss';
import logo_gem from '../assets/logo_gem.png';
import { Logo } from './Logo';


const Navbar = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);
  const location = useLocation();

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const isNavActive = (path: string) => location.pathname === path ? styles.activeLink : '';

  const NavLinkComponent = ({ target, text }: { target: string, text: string }) => {
    return (
      <NavLink to={target} className={isNavActive(target)} onClick={handleShowNavbar}>
        <li>{text}</li>
      </NavLink>
    );
  }

  return (
    <nav className={styles.navbarContainer}>
      <div className="content-container">
        <nav className={styles.navbar}>
          <NavLink to="/">
            <Logo size={30} />
          </NavLink>
          <div className={styles.menuIcon} onClick={handleShowNavbar}>
            {showNavbar ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M2 2L12 12M2 12L12 2" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                <path d="M2 2H20M2 8H20M2 14H20" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            )}
          </div>
          <div className={`${styles.links} ${showNavbar ? styles.showNavbar : ''}`}>
            <ul>
              <NavLinkComponent target='/about' text='About'/>
              <NavLinkComponent target='/audits' text='Audits'/>
              <NavLinkComponent target="/analytics" text='Analytics'/>
              <NavLinkComponent target="/contact" text='Contact'/>
            </ul>
          </div>
        </nav>
      </div>
    </nav>
  );
};


export default Navbar;
