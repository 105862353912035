import { Logo } from '../../../components/Logo';
import { ChainData, DashboardData } from '../../../models/DashboardData';
import { capitalizeFirstLetter } from '../Utility';
import styles from './SectionHeader.module.scss';

export default function SectionHeader({title, data, timeframe, chains}: {title: string, data: DashboardData | undefined, timeframe: string, chains: string[]}) {
  if (data === undefined) return <>Loading...</>;
  
  const chainOrder = ["ETH", "SOL", "BSC", "TRX"];  

  const sortedChains = chains.sort((a, b) => chainOrder.indexOf(a) - chainOrder.indexOf(b));

  return (
    <div className={styles.sectionHeader}>
      <div className={styles.title}>{title}</div>
      <div className={styles.tag}>1 {capitalizeFirstLetter(timeframe)}</div>
      <div className={styles.tag}>
        
        {sortedChains.map((chain) => (
          <img src={data?.chainInfo.find(x => x.chain == chain)?.iconUrl} alt={`Icon ${chain}`} className={styles.menuItemIcon2} />
        ))}

      </div>
      <div className={styles.logo}><Logo size={20}/></div>
    </div>
  )
}