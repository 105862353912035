import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ReviewData } from '../models/ReviewData';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DashboardData } from '../models/DashboardData';

//@ts-ignore
const baseUrl = process.env.REACT_APP_API_URL;

console.log(baseUrl)

const baseQuery = fetchBaseQuery({
  baseUrl
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ["Dashboard", "Contact", "Review"],
  endpoints: (builder) => ({
    getDashboard: builder.query<DashboardData, void>({
      query: () => '/dashboard',
      providesTags: ['Dashboard']
    }),
    getReview: builder.query<ReviewData, void>({
      query: () => '/review',
      providesTags: ['Review'],
    }),
    contact: builder.mutation<any, any>({
      query: (contact: any) => ({
          url: `/contact`,
          method: 'POST',
          body: contact
      }),
      invalidatesTags: ['Contact']
    }),
  })
})

export const {
  useGetDashboardQuery,
  useGetReviewQuery,
  useContactMutation
} = apiSlice;
