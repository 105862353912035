import styles from './Logo.module.scss';
import logo_gem from '../assets/logo_gem.png';


export const Logo = (size: {size: Number}) => {
  let sizePx = size.size + "px";

  return (
    <div className={styles.logo} style={{height: sizePx}}>
      <span style={{fontSize: sizePx}}>
        Tanzanite
      </span>
      <img src={logo_gem} alt='Tanzanite logo gem'/>
    </div>
  )
}